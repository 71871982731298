.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.ps-10 {
	padding-left: 10%;
}

.pe-40 {
	padding-right: 40%;
}

.py-48px {
	padding-top: 48px;
	padding-bottom: 48px;
}

.pe-8 {
	padding-right: 8%;
}

.p-1 {
	padding: 1%;
}

.p-32px {
	padding: 32px;
}

.p-15px {
	padding: 15px;
}

.p-16px {
	padding: 16px;
}

