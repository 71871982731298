$btn-white-space: nowrap;
$primary: #48b7e2;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #212121,
	'primary': #48b7e2,
	'primary-dark': #c7b800,
	'primary-light': #ffff56,
	'primary-text': #48b7e2,
	'secondary-text': #757575,
	'text-on-primary': #48b7e2,
	'text-on-accent': #000000,
	'background': #ffffff,
	'home-darken': #ef6c00de,
	'template_darken': #357b305c,
) !default;
