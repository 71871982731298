.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.max-w-100 {
	max-width: 100% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.w-320px {
	width: 320px !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-82px {
	width: 82px !important;
}

.max-w-1600px {
	max-width: 1600px !important;
}

.w-72px {
	width: 72px !important;
}

.w-65 {
	width: 65% !important;
}

.w-85 {
	width: 85% !important;
}

.w-500px {
	width: 500px !important;
}

.max-w-700px {
	max-width: 700px !important;
}

.w-400px {
	width: 400px !important;
}

.w-69 {
	width: 69% !important;
}

.w-50px {
	width: 50px !important;
}

